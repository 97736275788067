import logo from "./mochanic.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h4>The Car Doctor You Can Trust</h4>
        <img src={logo} alt="logo" className="App-logo" />
        <h1>0411 880 016</h1>
      </header>
    </div>
  );
}

export default App;
